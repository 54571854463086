export default {
  init() {
    // JavaScript to be fired on all pages

      $(document).ready(function(){
        var altura = $('.banner').height();
        console.log(altura);
        $(window).on('scroll', function(){
          if ( $(window).scrollTop() > altura ){
            $('.banner').addClass('menu-fixed');
          } else if ( $(window).scrollTop() < altura - 40 ){
            $('.banner').removeClass('menu-fixed');
          }
        });
      

      $('#mainnavigation a').on('click', function(){
        $('#mainnavigation').removeClass('show');
    });
    
    });
    

      
    $(document).ready(function(){
      $('.searchbutton a').click(function(e){
        e.preventDefault();
        $('.search-wrapper').slideToggle({duration: 500});
      });
    });


    $(document).ready(function(){
      $('.share-wrapper a.plus').click(function(e){
        e.preventDefault();
        $('.share-wrapper').toggleClass('show');
      });
    });


  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
